import { ENVIRONMENT } from 'emma-common-ts';
import { EMMAEnvironment } from 'emma-common-ts/emma';

export const environment: EMMAEnvironment = {
  production: true,
  s3Host: 'scw.cloud',
  s3SecretKey: '4af8e1fd-4ffd-4df1-9dc1-24ae61a8820e',
  s3AccessKey: 'SCWYJ8W02P2VW3BBKE1D',
  s3Region: 'nl-ams',
  shortLinkDomain: 's.pow.link',
  shortLinkProtocol: 'https',
  facebookAppId: '1194220137939018',
  googleAdsClientId: '976671935890-tk0jp657ksqvsd9vusv38fsd13hn0j37.apps.googleusercontent.com',
  gtmId: 'GTM-PHPTJHNP',
  env: ENVIRONMENT.STAGING,
};
